export const config = {
  appName: 'MedSym',
  apiUrl: 'https://api.medsym.io',
  pagination: {
    threadsPageSize: 10,
    usersPageSize: 15,
    demosPageSize: 10,
    contactUsRequestsPageSize: 10,
  },
  httpPolling: {
    groups: {
      enabled: true,
      interval: 30000,
    },
    group: {
      enabled: true,
      interval: 20000,
    },
    thread: {
      enabled: true,
      interval: 10000,
    },
  },
  googleTagId: 'GTM-TT84J7CG',
  isNative: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBEex063FCngbVgESVbfVqT72Hiz7svpUw',
    authDomain: 'medsym-f59c3.firebaseapp.com',
    projectId: 'medsym-f59c3',
    storageBucket: 'medsym-f59c3.appspot.com',
    messagingSenderId: '459238873291',
    appId: '1:459238873291:web:37ae64a662068ca60d634e',
  },
  firebaseWebPushSertificateKey:
    'BAzWj5iOm7sUcuk1i7cscAIlAh96dAsNQwAmg2pcmuAP0CvchW-OQYFGnVXWd0p515G1vhiga9BVJMfqzmEYkKI ',
};
